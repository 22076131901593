.products-list-page {
  .products-list {
    height: 100%;
    display: grid;
    grid-gap: 5vw;
    grid-template-columns: repeat(3, 1fr);
    // justify-items: start;
    // justify-content: center;
    // align-items: center;
  }
}
