@import './index';

.banner {
  grid-area: banner;
  // min-height: 100px;
}

.navbar {
  grid-area: navbar;
}

.content {
  margin: 5vw;
  grid-area: content;
}

.footer {
  grid-area: footer;
}

.wrapper {
  height: 100vh;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 125px 30px 1fr 30px;
  grid-template-areas:
    "banner"
    "navbar"
    "content"
    "footer";
}