// variables
// colors
$accent-color: #d30000;
$font-color: #000000;
$secondary-font-color: #ffffff;
$bg-color: #2d2d2d;
$navbar-footer-color: #000000;
// mobile break point
$mobile-width: 680px;

body {
  margin: 0;
  visibility: visible;
  background-color: $bg-color;
  //  fixes bug on usercard on mobile 
  color: $secondary-font-color;

  font-family: 'Jolly Lodger', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// mixins
@mixin mobile {
  @media ( max-width: $mobile-width ) {
    @content;
  }
}

@mixin box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.23);
}

// styling for responsive squares in css grid
@mixin square {
  position: relative;
  // too maintain 1:1 aspect ratio
  &.aspectRatioSizer {
    display: grid;
  }
  &.aspectRatioSizer > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  // sizing
  @include box-shadow;
  background-color: $accent-color;
  justify-self: stretch;
  align-self: stretch;
  min-width: 162px;
  height: 100%;
  
  @include mobile {
    margin: 15px 0;
  }
}

// // internal styling for stickys
// @mixin sticky {
//   @include box-shadow;
//   color: $font-color;

//   p{
//     margin: 2vw;
//     font-size: 4.4vw;
//     @include mobile {
//       font-size: 9vmin;
//   }
//     &.poster {
//       width: 80%;
//       margin: 0 auto;
//     }
//   }
// }

// // for pages with a single sticky
// @mixin centered-sticky {
//   @include sticky;
//   background-color: $accent-color;
//   min-width: 162px;
//   height: 30vw;
//   width: 30vw;
//   margin: 0 auto;

//       @include mobile {
//         height: 80vmin;
//         width: 80vmin;
//         min-width: 162px;
//     }
// }

// // error sticky
// .error {
//   @include centered-sticky;
//   height: 35vw;
//   width: 35vw;
//   font-size: 6vmin;
// }

// // loading animation
// .loading {
//   // position sticky in center of page
//   position:absolute;
//   left:calc(50% - 100px);
//   top:calc(50% - 100px);

//   // size and color of sticky
//   width: 200px;
//   height: 200px;
//   background: $accent-color;

//   // style and placement of text within sticky
//   color: #000000;
//   font-size: 30px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   // animation
//   animation-name: spinning-sticky;
//   animation-iteration-count: infinite;
//   animation-duration: 1.5s;
//   animation-timing-function: linear;

//   @keyframes spinning-sticky {
//     from {
//       transform: rotate(0);
//     }
//     to {
//       transform: rotate(360deg);
//     }