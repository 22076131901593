@import './index';
.footer {
  min-height: 30px;
  height: 100%;
  width: 100%;
  background-color: $navbar-footer-color;
  text-align: right;
  p {
    // "extends" the footer, when p wraps
    background-color: $navbar-footer-color;

    margin: 3px 0;
    padding: 0 10px;
    @include mobile {
      text-align: left;
    }
  }
  a {
    text-decoration: underline;
    color: $secondary-font-color;
  }
}