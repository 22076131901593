.product-page {
  display: grid;
  grid-template-columns: 1fr 1fr;

  // .product-images {
  //   grid-column-start: 1;
  // }

  // .product-info {
  //   grid-column-start: 2;
  // }
}
