.banner {
    /* responsiveness of image */
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    // height: 8vh;
    min-height: 125px;
    width: 100%;
    background: url("https://firebasestorage.googleapis.com/v0/b/maddelynn-hatter.appspot.com/o/madd_banner_cropped.jpg?alt=media&token=12303559-a358-4437-afca-a6833e4fd176");
    background-size: cover;
    background-position: center center;
    // padding-top: 30px;
}