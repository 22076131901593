@import "./index";

.product-card {
  @include square;
  @include box-shadow;
  background-color: #ffffff;

  // remove link styling
  text-decoration: none;
  color: $secondary-font-color;

  @include mobile {
    overflow: hidden;
    // fixes safari bug
    height: calc(100vw - 10vw);
  }

  .p-container {
    position: absolute;
    width: 100%; //calc(100% - 1px);
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 6px 0;
    background-color: rgba(45, 45, 45, 0.9);
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  p {
    line-height: 0;
    padding: 0 10px;
    // position: absolute;
    // width: 100%; //calc(100% - 1px);
    // bottom: 0;
    // left: 0;
    // margin: 0;
    // padding: 10px 0px 10px 10px;

    font-size: 2.5vw;

    @include mobile {
      font-size: 9vw;
    }
    .product-profie & {
      font-size: 4vw;

      @include mobile {
        font-size: 9vw;
      }
    }
  }

  img {
    min-width: 162px;

    // needed to stretch product photos properly across mobile and browsers
    max-width: 100%;
    width: 100%;
    height: 100%;

    @include mobile {
      // fixes mobile bug where img doesn-t stretch
      height: calc(100vw - 10vw);
    }
  }
}
.product-card:hover {
  cursor: pointer;
  color: $accent-color;

  @include mobile {
    // remove hover color
    color: inherit;
  }
}
