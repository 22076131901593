@import './index';
.navbar {
  height: 30px;
  width: 100%;
  background-color: $navbar-footer-color;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 300px 1fr 50px 50px 50px 50px;

}

.site-name {
  grid-column-start: 1;
  justify-self: left;
  margin: 0 10px;

  text-decoration: none;
  color: $accent-color;
  line-height: 0;
}

a {
  // remove link styling
 text-decoration: none;
 color: $secondary-font-color;
 
 }

.navbar-home {
  grid-column-start: 3;

//  span {
//     height: 28px;
//     width: 28px;
//     content: url("../icons/home-white.svg");
//     }

//   &.active span {
//       content: url("../icons/home-active.svg");
//   }
}

.navbar-tshirts {
  grid-column-start: 4;

  //  span {
  //     height: 28px;
  //     width: 28px;
  //     content: url("../icons/home-white.svg");
  //     }
  
  //   &.active span {
  //       content: url("../icons/home-active.svg");
  //   }
}

.navbar-wigs {
  grid-column-start: 5;

  //  span {
  //     height: 28px;
  //     width: 28px;
  //     content: url("../icons/home-white.svg");
  //     }
  
  //   &.active span {
  //       content: url("../icons/home-active.svg");
  //   }
}

.navbar-prints {
  grid-column-start: 6;

  //  span {
  //     height: 28px;
  //     width: 28px;
  //     content: url("../icons/home-white.svg");
  //     }
  
  //   &.active span {
  //       content: url("../icons/home-active.svg");
  //   }
}
