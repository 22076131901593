@import './index';

.tshirts {
  grid-area: tshirts;
}

.wigs {
  grid-area: wigs;
}

.prints {
  grid-area: prints;
}

.product-list {
  display: grid;
  grid-gap: 5vw;
  // grid-column: 1 / -1;
&.no-scroll {
  grid-template-columns: 35%;
}  

  // grid-auto-columns: 33vw;
&.scroll {
  grid-auto-flow: column;
  grid-auto-columns: 35%;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
}




  // scrollbar-width: none;
  // margin-bottom: 0;
  // padding-bottom: 0;
}
// .no-scrollbar::-webkit-scrollbar {
//   display: none;
// }


.home {
  height: 100%;
  display: grid;
  grid-gap: 5vw;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  // justify-items: start;
  // justify-content: center;
  // align-items: center;


  grid-template-areas:
  "tshirts"
  "wigs"
  "prints";

  @include mobile {
    display: none;
    display: block;
  }
}